@tailwind base;
@tailwind components;
@tailwind utilities;

@media (prefers-color-scheme: dark) {
  html {
    color-scheme: dark;
  }
  body {
    background-color: "#1e293b";
  }
}
